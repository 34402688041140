import React from "react";

import { Box, Text } from "@chakra-ui/react";

import Markdown from "./Markdown";

interface SwitchboardThankYouPageProps {
  header?: string;
  body?: string;
}

const SwitchboardThankYouPage = ({ header, body }: SwitchboardThankYouPageProps): JSX.Element => {
  return (
    <Box
      py={10}
      flex={1}
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      textAlign="left"
      flexDirection="column"
      width="full"
      height="full"
      maxWidth="xl"
    >
      <Box fontSize="5xl" fontWeight="600" lineHeight={"1.1"} opacity={0.9}>
        <Markdown>{header || "# Thank you!"}</Markdown>
      </Box>
      <Box my={3} fontSize="lg">
        <Markdown>{body || "Your submission has been received."}</Markdown>
      </Box>
    </Box>
  );
};

export default SwitchboardThankYouPage;
