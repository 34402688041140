import { Icon } from "@chakra-ui/react";

import { IconType } from "react-icons";

export type TimestampString =
  `${number}-${number}-${number}T${number}:${number}:${number}.${number}-${number}:${number}`;

/* *******************      START SHARED WEB FORM TYPES      ******************* */

export interface ContributionSchema {
  amountPresetsInCents: number[];
  defaultAmountInCents: number;
  recurringFrequency: RecurringContributionFrequency;
  recurringDefaultChecked: boolean;
  tipUpsell: boolean; // In the future, use an array or something so we can reorder upsells.
  disclosure: string; // markdown
}

export interface WebFormSchema {
  page?: {
    title?: string;
    description?: string;
    footer?: string;
    paidForBy?: string;
    banner?: string;
    seo?: any; // TODO: Implement with https://github.com/garmeeh/next-seo
    backgroundColor?: string;
  };
  form: {
    items: ConstructedFormItem[];
    submitText?: string;
    postSubmitHeader?: string;
    postSubmitBody?: string;
    postSubmitRedirect?: boolean;
    postSubmitRedirectUrl?: any;
    closedHeader?: string;
    closedBody?: string;
  };
  contribution?: ContributionSchema;
}

export interface WebFormApiObject {
  title: string;
  slug: string;
  organization: string;
  organization_name: string;
  created: TimestampString;
  modified: TimestampString;
  schema: WebFormSchema;
  is_closed: boolean;
  webform_type: "CONTRIBUTION" | "OTHER";
  public_id: string;
}

// TODO: Properly type this. It's a _JSON Form Schema_, but I haven't found a type in the SDK that matches it yet.
export type EditorSchema = any;

// TODO: Properly typed, this could make writing custom widgets much easier.
// These are the values that [react-jsonschema-form](https://react-jsonschema-form.readthedocs.io> /en/latest/) hands off to every widget.
export interface CustomWidgetProps {
  id: string; //The generated id for this widget;
  schema: any; //The JSONSchema subschema object for this widget;
  uiSchema: any; // The uiSchema for this widget;
  value: any; // The current value for this widget;
  placeholder: any; // the placeholder for the field, if any;
  required: boolean; //The required status of this widget;
  disabled: boolean; // true if the widget is disabled;
  readonly: boolean; // true if the widget is read-only;
  autofocus: boolean; // true if the widget should autofocus;
  onChange: any; // The value change event handler; call it with the new value every time it changes;
  onKeyChange: any; //The key change event handler (only called for fields with additionalProperties); pass the new value every time it changes;
  onBlur: any; // The input blur event handler; call it with the the widget id and value;
  onFocus: any; // The input focus event handler; call it with the the widget id and value;
  options: any; // A map of options passed as a prop to the component (see Custom widget options).
  //options.enumOptions: any //For enum fields, this property contains the list of options for the enum as an array of { label, value } objects. If the enum is defined using the oneOf/anyOf syntax, the entire schema object for each option is appended onto the { schema, label, value } object.
  formContext: any; // The formContext object that you passed to Form.
  rawErrors: any; // An array of strings listing all generated error messages from encountered errors for this widget.
  registry: any; // A registry object (read next).
}

export type NodeType =
  | "checkbox"
  | "date"
  | "dropdown"
  | "email"
  | "firstName"
  | "lastName"
  | "multipleSelect"
  | "multipleChoice"
  | "number"
  | "paragraph"
  | "phoneNumber"
  | "text"
  | "time"
  | "toggleSwitch"
  | "url"
  | "zipCode";

export type JsonDataType = "string" | "number" | "integer" | "boolean" | "array" | "object";

export type FormField = {
  title: string;
  dataType: JsonDataType;
  description: string;
  widget?: JsonSchemaWidget;
  getInitialConfiguration?: () => { choices?: any[] };
  getDefaultValue: () => any;
  hideInEditor?: boolean;
  icon?: IconType | typeof Icon;
  nodeType: NodeType;
  fieldId?: string;
  version: string;
  accordionBreak?: string;
  editorUI: any;
  editorSchema: EditorSchema;
  dataToSchema: (data: any) => any;
  dataToUi: (data: any) => any;
  comingSoon?: boolean;
};

export type ConstructedFormItem = {
  itemId: string;
  hidden?: boolean;
  version: string;
  nodeType: NodeType;
  data?: any;
};

export type JsonSchemaFields =
  | "ArrayField"
  | "BooleanField"
  | "DescriptionField"
  | "OneOfField"
  | "AnyOfField"
  | "NullField"
  | "NumberField"
  | "ObjectField"
  | "SchemaField"
  | "StringField"
  | "TitleField"
  | "UnsupportedField";

export type VendorJsonSchemaWidget =
  | "AltDateTimeWidget"
  | "AltDateWidget"
  | "CheckboxesWidget"
  | "CheckboxWidget"
  | "ColorWidget"
  | "DateTimeWidget"
  | "DateWidget"
  | "EmailWidget"
  | "FileWidget"
  | "HiddenWidget"
  | "PasswordWidget"
  | "RadioWidget"
  | "RangeWidget"
  | "SelectWidget"
  | "TextareaWidget"
  | "TextWidget"
  | "UpDownWidget"
  | "URLWidget";

export type CustomJsonSchemaWidget = "TimeWidget" | "ToggleWidget";

export type JsonSchemaWidget = VendorJsonSchemaWidget | CustomJsonSchemaWidget;

/* *******************      END SHARED WEB FORM TYPES      ******************* */

// Painstakingly typed by hand after clicking through each option in Chrome. 😔
// Reordered with some entries removed for simplicity.
export const PreviewDimensionMap: Readonly<Record<string, { width: number; height: number }>> = {
  "iPhone SE": { width: 375, height: 667 },
  "iPhone Pro": { width: 390, height: 844 },
  "iPhone Pro Max": { width: 430, height: 932 },
  "iPad Mini": { width: 768, height: 1024 },
  "iPad Air": { width: 820, height: 1180 },
  "iPad Pro": { width: 1024, height: 1366 },
  "Pixel 7": { width: 412, height: 915 },
  "Samsung Galaxy S8+": { width: 360, height: 740 },
  "Samsung Galaxy S20 Ultra": { width: 412, height: 915 },
  "Samsung Galaxy A51/71": { width: 412, height: 914 },
};

export enum RecurringContributionFrequency {
  null = "null",
  ONCE = "once",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}
