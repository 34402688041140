import React, { ChangeEvent, FormEventHandler, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  VStack,
} from "@chakra-ui/react";

import { DonorPrefillInfo } from "@lib/contributions";

export interface DonorFormState {
  donorFirstName: string;
  donorLastName: string;
  donorEmail: string;
  donorPhone: string;
  donorAddress: string;
  donorCity: string;
  donorState: string;
  donorPostalCode: string;
  donorCountry: string;
  donorEmployer: string;
  donorOccupation: string;
  donorEmploymentStatus: "EMPLOYED" | "RETIRED";
}

interface DonorFormProps {
  donorPrefillInfo: DonorPrefillInfo;
  onSubmit: (data: DonorFormState) => void;
  orgName: string;
  orgPrivacyPolicyUrl?: string;
}

const DonorForm = ({ donorPrefillInfo, onSubmit, orgName, orgPrivacyPolicyUrl }: DonorFormProps): JSX.Element => {
  const [formState, setFormState] = useState<DonorFormState>({
    donorFirstName: "",
    donorLastName: "",
    donorEmail: "",
    donorPhone: "",
    donorAddress: "",
    donorCity: "",
    donorState: "",
    donorPostalCode: "",
    donorCountry: "",
    donorEmployer: "",
    donorOccupation: "",
    donorEmploymentStatus: "EMPLOYED",
  });

  useEffect(() => {
    setFormState({
      donorFirstName: donorPrefillInfo?.firstName || "",
      donorLastName: donorPrefillInfo?.lastName || "",
      donorEmail: donorPrefillInfo?.email || "",
      donorPhone: donorPrefillInfo?.phone || "",
      donorAddress: donorPrefillInfo?.address || "",
      donorCity: donorPrefillInfo?.city || "",
      donorState: donorPrefillInfo?.state || "",
      donorPostalCode: donorPrefillInfo?.zipCode || "",
      donorCountry: donorPrefillInfo?.country || "",
      donorEmployer: donorPrefillInfo?.employer || "",
      donorOccupation: donorPrefillInfo?.occupation || "",
      donorEmploymentStatus: "EMPLOYED",
    });
  }, [donorPrefillInfo]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [isRetired, setIsRetired] = useState<boolean>(formState.donorEmploymentStatus === "RETIRED");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setIsProcessing(true);

    try {
      await onSubmit({
        ...formState,
        donorEmployer: isRetired ? "" : formState.donorEmployer,
        donorOccupation: isRetired ? "" : formState.donorOccupation,
        donorEmploymentStatus: isRetired ? "RETIRED" : "EMPLOYED",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <VStack spacing={1} as="form" onSubmit={handleSubmit} width="full" autoComplete="on">
      <FormControl>
        <FormLabel fontSize="sm" mb={0}>
          Email
        </FormLabel>
        <Input
          type="email"
          name="donorEmail"
          id="donorEmail"
          value={formState.donorEmail}
          onChange={handleChange}
          autoComplete="email"
          placeholder="Email"
          isRequired
        />
      </FormControl>
      <HStack width="full">
        <FormControl>
          <FormLabel fontSize="sm" mb={0}>
            First Name
          </FormLabel>
          <Input
            type="text"
            name="donorFirstName"
            id="donorFirstName"
            value={formState.donorFirstName}
            onChange={handleChange}
            autoComplete="name"
            placeholder="Name"
            isRequired
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" mb={0}>
            Last Name
          </FormLabel>
          <Input
            type="text"
            name="donorLastName"
            id="donorLastName"
            value={formState.donorLastName}
            onChange={handleChange}
            autoComplete="family-name"
            placeholder="Last Name"
            isRequired
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel fontSize="sm" mb={0}>
          Address
        </FormLabel>
        <Input
          type="text"
          name="donorAddress"
          id="donorAddress"
          value={formState.donorAddress}
          onChange={handleChange}
          autoComplete="street-address"
          placeholder="Address"
          isRequired
        />
      </FormControl>
      <HStack>
        <FormControl>
          <FormLabel fontSize="sm" mb={0}>
            City
          </FormLabel>
          <Input
            type="text"
            name="donorCity"
            id="donorCity"
            value={formState.donorCity}
            onChange={handleChange}
            autoComplete="address-level2"
            placeholder="City"
            isRequired
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" mb={0}>
            State
          </FormLabel>
          <Input
            type="text"
            name="donorState"
            id="donorState"
            value={formState.donorState}
            onChange={handleChange}
            autoComplete="address-level1"
            placeholder="State"
            isRequired
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" mb={0}>
            Zip
          </FormLabel>
          <Input
            type="text"
            name="donorPostalCode"
            id="donorPostalCode"
            value={formState.donorPostalCode}
            onChange={handleChange}
            autoComplete="postal-code"
            placeholder="Zip"
            isRequired
          />
        </FormControl>
      </HStack>
      <FormControl pt={5} width="full">
        <Checkbox
          fontSize="sm"
          name="donorRetired"
          id="donorRetired"
          checked={isRetired}
          onChange={() => setIsRetired(!isRetired)}
        >
          I am retired
        </Checkbox>
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" mb={0}>
          Occupation
        </FormLabel>
        <Input
          type="text"
          name="donorOccupation"
          id="donorOccupation"
          value={isRetired ? "" : formState.donorOccupation}
          onChange={handleChange}
          autoComplete="organization-title"
          placeholder="Occupation"
          isRequired={!isRetired}
          isDisabled={isRetired}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="sm" mb={0}>
          Employer
        </FormLabel>
        <Input
          type="text"
          name="donorEmployer"
          id="donorEmployer"
          value={isRetired ? "" : formState.donorEmployer}
          onChange={handleChange}
          autoComplete="organization"
          placeholder="Employer"
          isRequired={!isRetired}
          isDisabled={isRetired}
        />
      </FormControl>
      <FormControl pt={5}>
        <FormLabel fontSize="sm" mb={0}>
          Phone (Optional)
        </FormLabel>
        <Input
          type="tel"
          name="donorPhone"
          id="donorPhone"
          value={formState.donorPhone}
          onChange={handleChange}
          autoComplete="tel"
          placeholder="Phone"
        />
        <FormHelperText fontSize="2xs" px={2} mb={2}>
          By providing your phone number here, you consent to receive recurring committee & fundraising messages from{" "}
          {orgName}, including automated text messages. Msg & Data rates may apply.
          {orgPrivacyPolicyUrl && (
            <>
              &nbsp;
              <Link color="blue.500" textDecoration="underline" href={orgPrivacyPolicyUrl} isExternal>
                Privacy Policy &amp; ToS
              </Link>
              .
            </>
          )}
        </FormHelperText>
      </FormControl>

      <Button type="submit" colorScheme="blue" width="full" isLoading={isProcessing}>
        Submit
      </Button>
    </VStack>
  );
};

export default DonorForm;
