import { createIcon } from "@chakra-ui/react";

const DesktopIcon = createIcon({
  displayName: "DesktopIcon",
  viewBox: "0 0 18 16",
  path: (
    <path
      d="M16.0556 0.5H2.44444C1.37044 0.5 0.5 1.33936 0.5 2.375V10.8125C0.5 11.8481 1.37044 12.6875 2.44444 12.6875H6.95009L6.65842 14.0938H5.11806C4.71701 14.0938 4.38889 14.4102 4.38889 14.7969C4.38889 15.1836 4.71701 15.5 5.11806 15.5H13.3819C13.7845 15.5 14.1111 15.1851 14.1111 14.7969C14.1111 14.4087 13.7845 14.0938 13.3819 14.0938H11.8407L11.5499 12.6875H16.0556C17.1296 12.6875 18 11.8481 18 10.8125V2.375C18 1.33936 17.128 0.5 16.0556 0.5ZM10.3529 14.0938H8.14714L8.4388 12.6875H10.063L10.3529 14.0938ZM16.5417 10.8125C16.5417 11.071 16.3236 11.2812 16.0556 11.2812H2.44444C2.17641 11.2812 1.95833 11.071 1.95833 10.8125V9.40625H16.5417V10.8125ZM16.5417 8H1.95833V2.375C1.95833 2.11654 2.17641 1.90625 2.44444 1.90625H16.0556C16.3236 1.90625 16.5417 2.11654 16.5417 2.375V8Z"
      fill="currentColor"
    />
  ),
});

export default DesktopIcon;
