import axios from "axios";

import { BASE_BACKEND_API_URL } from "./constants";
import { RecurringContributionFrequency } from "./types";

/** Information to prefill donor forms with when available. */
export interface DonorPrefillInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  employer?: string;
  occupation?: string;
}

interface ContributionsOrganizationDataResponse {
  stripeConnectedAccountId: string;
}

export const getContributionsOrganizationData = async (
  formId: string
): Promise<ContributionsOrganizationDataResponse> => {
  const response = await axios.get(
    `${BASE_BACKEND_API_URL}/public/contributions/organization_data/?webform_public_id=${formId}`
  );

  return { stripeConnectedAccountId: response?.data?.stripe_connected_account_id };
};

interface SetupIntentResponse {
  setupIntentClientSecret: string;
}

export const getSetupIntentClientSecret = async (formClientSecret: string): Promise<SetupIntentResponse> => {
  const response = await axios.post(`${BASE_BACKEND_API_URL}/public/contributions/pending_contribution_setup_intent/`, {
    client_secret: formClientSecret,
  });

  return {
    setupIntentClientSecret: response?.data?.setup_intent_client_secret,
  };
};

interface CreatePendingContributionData {
  webformPublicId: string;
  amountInCents: number;
  recurringContributionFrequency: RecurringContributionFrequency;
  isCoveringProcessingFee: boolean;
}

interface UpdatePendingContributionData {
  clientSecret: string;
  amountInCents: number;
  recurringContributionFrequency: RecurringContributionFrequency;
  isCoveringProcessingFee: boolean;
  donorFirstName: string;
  donorLastName: string;
  donorEmail: string;
  donorPhone: string;
  donorAddress: string;
  donorCity: string;
  donorState: string;
  donorPostalCode: string;
  donorCountry: string;
  donorEmployer: string;
  donorOccupation: string;
  donorEmploymentStatus: "EMPLOYED" | "RETIRED";
  scheduleCharge?: boolean;
  chargeNow?: boolean;
}

interface PendingContribution {
  client_secret: string;
  amount_in_cents: Number;
  is_covering_processing_fee: boolean;
  covered_processing_fee_in_cents: Number;
  donor_first_name: string;
  donor_last_name: string;
  donor_email: string;
  donor_phone: string;
  donor_address: string;
  donor_city: string;
  donor_state: string;
  donor_postal_code: string;
  donor_country: string;
  donor_employer: string;
  donor_occupation: string;
  schedule_charge?: boolean;
}

export const createPendingContribution = async ({
  webformPublicId: webformPublicId,
  amountInCents,
  recurringContributionFrequency,
  isCoveringProcessingFee,
}: CreatePendingContributionData): Promise<PendingContribution> => {
  const response = await axios.post<PendingContribution>(
    `${BASE_BACKEND_API_URL}/public/contributions/create_pending_contribution/`,
    {
      webform_public_id: webformPublicId,
      amount_in_cents: amountInCents,
      recurring_contribution_frequency: recurringContributionFrequency,
      is_covering_processing_fee: isCoveringProcessingFee,
    }
  );
  return response.data;
};

export const updatePendingContribution = async ({
  clientSecret,
  amountInCents,
  recurringContributionFrequency,
  isCoveringProcessingFee,
  donorFirstName,
  donorLastName,
  donorEmail,
  donorPhone,
  donorAddress,
  donorCity,
  donorState,
  donorPostalCode,
  donorCountry,
  donorEmployer,
  donorOccupation,
  donorEmploymentStatus,
  scheduleCharge = false,
  chargeNow = false,
}: UpdatePendingContributionData): Promise<PendingContribution> => {
  if (chargeNow) {
    console.log("Charging now from updatePendingContribution");
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  const response = await axios.post<PendingContribution>(
    `${BASE_BACKEND_API_URL}/public/contributions/update_pending_contribution/`,
    {
      client_secret: clientSecret,
      amount_in_cents: amountInCents,
      recurring_contribution_frequency: recurringContributionFrequency,
      is_covering_processing_fee: isCoveringProcessingFee,
      donor_first_name: donorFirstName,
      donor_last_name: donorLastName,
      donor_email: donorEmail,
      donor_phone: donorPhone,
      donor_address: donorAddress,
      donor_city: donorCity,
      donor_state: donorState,
      donor_postal_code: donorPostalCode,
      donor_country: donorCountry,
      donor_employer: donorEmployer,
      donor_occupation: donorOccupation,
      donor_employment_status: donorEmploymentStatus,
      schedule_charge: scheduleCharge,
      charge_now: chargeNow,
    }
  );
  return response.data;
};

interface ChargeData {
  formClientSecret: string;
}

export const scheduleCharge = async ({ formClientSecret }: ChargeData): Promise<void> => {
  const result = await axios.post(`${BASE_BACKEND_API_URL}/public/contributions/schedule_charge/`, {
    form_client_secret: formClientSecret,
  });

  return result.data;
};

export const chargeNow = async ({ formClientSecret }: ChargeData): Promise<void> => {
  axios.post(`${BASE_BACKEND_API_URL}/public/contributions/charge_now/`, {
    form_client_secret: formClientSecret,
  });
};

interface AddLineItemData {
  formClientSecret: string;
  amountInCents: number;
  type: string;
  chargeNow?: boolean;
  scheduleCharge?: boolean;
}

export const addLineItem = async ({
  formClientSecret,
  amountInCents,
  type,
  chargeNow = false,
  scheduleCharge = false,
}: AddLineItemData) => {
  await axios.post(`${BASE_BACKEND_API_URL}/public/contributions/add_line_item/`, {
    form_client_secret: formClientSecret,
    amount_in_cents: amountInCents,
    type,
    charge_now: chargeNow,
    schedule_charge: scheduleCharge,
  });
};
