import { useState } from "react";

import { Button, Input, InputGroup, InputLeftAddon, Text, VStack } from "@chakra-ui/react";

import { addLineItem, chargeNow } from "@lib/contributions";

const currencyFormat = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

interface TipFormProps {
  formClientSecret: string;
  defaultTipInCents: number;
  donationAmountInCents: number;
  orgName: string;
}

const TipForm = ({ formClientSecret, defaultTipInCents, donationAmountInCents, orgName }: TipFormProps) => {
  const [tipAmountInCents, setTipAmountInCents] = useState<number | undefined>(defaultTipInCents);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [showTipMenu, setShowTipMenu] = useState<boolean>(true);

  const handleAddTip = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!tipAmountInCents) {
      return;
    }
    setIsLoading(true);
    try {
      await addLineItem({
        formClientSecret,
        amountInCents: tipAmountInCents,
        type: "UPSELL_TIP",
        chargeNow: true,
      });
      setIsComplete(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNoTipContinue = async () => {
    setShowTipMenu(false);
    await chargeNow({ formClientSecret });
    setIsComplete(true);
  };

  const handleCustomAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Check if the input has more than 2 decimal places
    if (!/^\d*\.?\d{0,2}$/.test(inputValue)) {
      e.target.value = inputValue.slice(0, -1);
      return; // Don't update if there are more than 2 decimal places
    }

    const value = parseFloat(inputValue);
    if (isNaN(value)) {
      setTipAmountInCents(undefined);
    } else {
      setTipAmountInCents(Math.round(value * 100));
    }
  };

  const handleCustomAmountInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();

    if (inputValue === "") {
      // Set to undefined if the input is empty
      setTipAmountInCents(undefined);
      e.target.value = "";
      return;
    }

    let value = parseFloat(inputValue);

    if (isNaN(value)) {
      value = 0;
    }

    // Format as USD with 2 decimal places
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    // Remove the dollar sign as we're working with numbers
    const numericValue = formattedValue.replace(/[$,]/g, "");

    // Update the input value and state
    e.target.value = numericValue;
    setTipAmountInCents(Math.round(parseFloat(numericValue) * 100));
  };

  return (
    <VStack spacing="0">
      <Text>Thank you for your contribution to the {orgName}. 💙</Text>
      <Text marginBottom="6">You will receive a receipt via email.</Text>
      {showTipMenu && (
        <>
          <VStack
            borderRadius="2xl"
            backgroundColor="gray.100"
            padding="6"
            display={!isComplete ? "flex" : "none"}
            spacing={4}
          >
            <form onSubmit={handleAddTip}>
              <VStack spacing={4}>
                <Text fontSize="xl" fontWeight="800">
                  Leave a tip for {orgName}
                </Text>
                <Text>
                  Add a tip to your contribution to help {orgName} cover transaction fees and further our mission.
                </Text>
                <InputGroup>
                  <InputLeftAddon>$</InputLeftAddon>
                  <Input
                    backgroundColor="white"
                    type="number"
                    step="any"
                    defaultValue={(defaultTipInCents / 100).toFixed(2)}
                    onChange={handleCustomAmountInputChange}
                    onBlur={handleCustomAmountInputBlur}
                    isDisabled={isLoading}
                  />
                </InputGroup>
                <Button
                  width="full"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isLoading}
                  isDisabled={isLoading || !tipAmountInCents}
                >
                  Add {tipAmountInCents ? currencyFormat.format(tipAmountInCents / 100) : ""} Tip
                </Button>
              </VStack>
            </form>
            <Button variant="link" onClick={handleNoTipContinue} colorScheme="black" type="button" disabled={isLoading}>
              No Tip, Continue
            </Button>
          </VStack>

          <VStack borderRadius="2xl" backgroundColor="gray.100" padding="6" display={isComplete ? "flex" : "none"}>
            <Text fontSize="xl" fontWeight="800">
              Your tip has been added!
            </Text>
            <Text>
              Your contribution and tip will appear as one charge of{" "}
              {/* TODO : eventually, we should get this amount from the backend totalling all line items or something */}
              {currencyFormat.format((donationAmountInCents + (tipAmountInCents || 0)) / 100)}.
            </Text>
          </VStack>
        </>
      )}
    </VStack>
  );
};

export default TipForm;
