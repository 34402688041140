import React from "react";

import { Box, Button, Text } from "@chakra-ui/react";

class ErrorBoundary extends React.Component {
  constructor(props: { children?: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  // No hook for componentDidCatch
  // https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
  componentDidCatch(error: any, errorInfo: any) {
    console.log({ error, errorInfo });
  }
  render() {
    // @ts-ignore
    if (this.state.hasError === true) {
      return (
        <Box>
          <Text as="h2">Oops, there is an error!</Text>
          <Button type="button" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </Button>
        </Box>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
