import axios from "axios";

import { BASE_BACKEND_API_URL } from "./constants";

export const getDraftForm = async (previewId: string) => {
  const response = await axios.get(`${BASE_BACKEND_API_URL}/public/webforms/draft/${previewId}/`);

  return response.data;
};

export const getPublishedForm = async (orgSlug: string, formSlug: string) => {
  const response = await axios.get(`${BASE_BACKEND_API_URL}/public/webforms/published/${orgSlug}/${formSlug}/`);
  return response.data;
};

export const getPublishedFormByDomain = async (customDomain: string, formSlug: string) => {
  const response = await axios.get(
    `${BASE_BACKEND_API_URL}/public/webforms/published-by-domain/${customDomain}/${formSlug}/`
  );
  return response.data;
};

export const formSubmit = async (orgSlug: string, formSlug: string, data: any) => {
  const url = `${BASE_BACKEND_API_URL}/public/webforms/submit/${orgSlug}/${formSlug}/`;
  const sessionId = localStorage.getItem("sessionId") || "";
  const response = await axios.post(url, { data }, { headers: { "X-WebForm-Session-ID": sessionId } });
  return response.data;
};

export const formView = async (orgSlug: string, formSlug: string) => {
  const sessionId = localStorage.getItem("sessionId") || "";
  const url = `${BASE_BACKEND_API_URL}/public/webforms/view/${orgSlug}/${formSlug}/`;
  const response = await axios.post(url, {}, { headers: { "X-WebForm-Session-ID": sessionId } });
  return response.data;
};
