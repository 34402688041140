import React from "react";

import Head from "next/head";

import { WebFormApiObject } from "../lib/types";

interface FormHeadProps {
  form: WebFormApiObject;
}

const FormHead = ({ form }: FormHeadProps): JSX.Element => {
  const banner = form.schema?.page?.banner;
  const description = form.schema?.page?.description;
  const title = form.schema?.page?.title || form.title;

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      {!!description && <meta property="og:description" content={description} key="description" />}
      {!!banner && <meta property="og:image" content={banner} key="image" />}
      {/* TODO: ADD THE URL TO THE FORM PAYLOAD */}
      {/* <meta property="og:url" content={``} key="url" /> */}
      <meta property="og:type" content="website" key="type" />
      <meta property="og:locale" content="en_US" key="locale" />
      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:title" content={title} key="twitter:title" />
      {!!description && <meta name="twitter:description" content={description} key="twitter:description" />}
      {!!banner && <meta name="twitter:image" content={banner} key="twitter:image" />}
    </Head>
  );
};

export default FormHead;
