import { FormEvent, useEffect, useState } from "react";

import { Box, Spinner } from "@chakra-ui/react";

import { formSubmit } from "@lib/api";
import Form from "@rjsf/chakra-ui";
import { IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

import useDebouncedState from "../lib/useDebounce";
import ErrorBoundary from "./ErrorBoundary";

interface RenderSchemaProps {
  mode: "preview" | "published";
  orgSlug: string;
  formSlug: string;
  schema?: any;
  uiSchema?: any;
  initialData?: any;
  verbose?: boolean;
  onUpdate?: (formData: any) => void;
  onComplete: (data: any) => void;
  refcodeSB: string | null;
}

const RenderSchema = ({
  mode,
  orgSlug,
  formSlug,
  schema,
  uiSchema,
  onUpdate,
  onComplete,
  refcodeSB,
  verbose = false,
  initialData = {},
}: RenderSchemaProps): JSX.Element | null => {
  const [formData, setFormData, _formData] = useDebouncedState(initialData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    !!onUpdate && onUpdate(formData);
  }, [onUpdate, formData]);

  const handleChange = (result: any): void => {
    const { formData } = result;
    setFormData(formData);
  };

  const handleSubmit = (e: IChangeEvent<any>, nativeEvent: FormEvent<HTMLFormElement>): void => {
    nativeEvent.preventDefault();
    setIsSubmitting(true);
    if (e.status === "submitted") {
      if (mode === "preview") {
        alert("Preview Forms cannot be submitted.");
        if (verbose) {
          console.log(e);
        }
      } else {
        const submitData = e.formData;
        if (!!refcodeSB) {
          submitData.refcodeSB = refcodeSB;
        }

        formSubmit(orgSlug!, formSlug!, submitData)
          .then((data) => onComplete(data))
          .finally(() => setIsSubmitting(false));
      }
    }
  };

  if (!schema) {
    return null;
  }

  return (
    <Box flex={1} height="full" width="full" position="relative">
      <ErrorBoundary>
        <Box opacity={isSubmitting ? 0.5 : 1}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            onChange={handleChange}
            formData={_formData}
            onSubmit={handleSubmit}
            validator={validator}
            disabled={isSubmitting}
          />
        </Box>
      </ErrorBoundary>
      {isSubmitting && <Spinner position="absolute" top={0} left={0} right={0} bottom={0} margin="auto" />}
    </Box>
  );
};

export default RenderSchema;
