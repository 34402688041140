import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Checkbox, Grid, Input, InputGroup, InputLeftAddon, Text, VStack } from "@chakra-ui/react";

import DonorForm, { DonorFormState } from "@components/contributions/DonorForm";
import TipForm from "@components/contributions/TipForm";
import {
  DonorPrefillInfo,
  chargeNow,
  createPendingContribution,
  getContributionsOrganizationData,
  getSetupIntentClientSecret,
  scheduleCharge,
  updatePendingContribution,
} from "@lib/contributions";
import { ContributionSchema, RecurringContributionFrequency } from "@lib/types";
import camelCase from "lodash/camelCase";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

// Dynamic import with no SSR to avoid pre-loading the Stripe SDK
const CheckoutForm = dynamic(() => import("@components/contributions/CheckoutForm"), { ssr: false });
const PaymentMethodButtons = dynamic(() => import("@components/contributions/PaymentMethodButtons"), { ssr: false });

enum Step {
  /** Donor is asked how much they would like to donate and what payment method to use. */
  SelectAmount,
  /** Donor is asked for their address, email, etc. */
  DonorInfo,
  /** (If applicable) Donor is asked for their credit card information. */
  CreditCard,
  /** Donor is asked if they are willing to leave a tip. */
  LeaveTip,
}

interface ContributionFormProps {
  formId?: string;
  onComplete: (data: any) => void;
  schema: ContributionSchema;
  orgName: string;
  orgPrivacyPolicyUrl?: string;
}

const getQueryValue = (value: string | string[]): string =>
  typeof value === "string" ? decodeURIComponent(value) : decodeURIComponent(value.join(""));

const ContributionForm = ({
  formId,
  onComplete,
  schema,
  orgName,
  orgPrivacyPolicyUrl,
}: ContributionFormProps): JSX.Element => {
  const [selectedAmount, setSelectedAmount] = useState<number | undefined>(
    schema.amountPresetsInCents.includes(schema.defaultAmountInCents) ? schema.defaultAmountInCents : undefined
  );
  const [customAmount, setCustomAmount] = useState<number | undefined>(
    schema.amountPresetsInCents.includes(schema.defaultAmountInCents) ? undefined : schema.defaultAmountInCents
  );
  const [recurring, setRecurring] = useState<boolean>(schema.recurringDefaultChecked);

  const [readyForCheckout, setReadyForCheckout] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formClientSecret, setFormClientSecret] = useState<string>("");
  const [stripeConnectedAccountId, setStripeConnectedAccountId] = useState<string>("");
  const [donorPrefillInfo, setDonorPrefillInfo] = useState<DonorPrefillInfo>({});
  const [needCardInfo, setNeedCardInfo] = useState<boolean>(false);
  const [step, setStep] = useState<Step>(Step.SelectAmount);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>("");
  const { query } = useRouter();

  useEffect(() => {
    if (formId !== undefined) {
      getContributionsOrganizationData(formId).then((orgInfo) => {
        setStripeConnectedAccountId(orgInfo.stripeConnectedAccountId);
      });
    }
  }, [formId]);

  const queryPrefillData = useMemo(() => {
    return Object.entries(query).reduce((acc, [key, value]) => {
      if (!key || !value) {
        return acc;
      }

      switch (key) {
        case "address_1":
          return { ...acc, address: getQueryValue(value) };
        case "postal_code":
          return { ...acc, zipCode: getQueryValue(value) };
        default:
          return {
            ...acc,
            [camelCase(key)]: getQueryValue(value),
          };
      }
    }, {});
  }, [query]);

  const handleCreatePendingContribution = async (donorPrefillInfo: DonorPrefillInfo): Promise<string> => {
    let clientSecret: string = "";
    if (!formId || (selectedAmount || customAmount || 0) === 0) {
      return clientSecret;
    }
    setIsLoading(true);
    setDonorPrefillInfo(donorPrefillInfo);
    try {
      const pendingContribution = await createPendingContribution({
        webformPublicId: formId,
        amountInCents: selectedAmount || customAmount || 0,
        recurringContributionFrequency: recurring ? schema.recurringFrequency : RecurringContributionFrequency.ONCE,
        isCoveringProcessingFee: false,
      });
      clientSecret = pendingContribution.client_secret;
      setFormClientSecret(clientSecret);
    } finally {
      setIsLoading(false);
      return clientSecret;
    }
  };

  const getFormClientSecret = async () => {
    if (formClientSecret) {
      return formClientSecret;
    }
    return await handleCreatePendingContribution({});
  };

  const getStripeClientSecret = async () => {
    if (stripeClientSecret) {
      return stripeClientSecret;
    }

    const _formClientSecret = await getFormClientSecret();

    if (!_formClientSecret) {
      return "";
    }

    const { setupIntentClientSecret } = await getSetupIntentClientSecret(_formClientSecret);
    setStripeClientSecret(setupIntentClientSecret);
    return setupIntentClientSecret;
  };

  const handleCheckoutFormConfirm = async ({ preventSchedule = false }: { preventSchedule?: boolean }) => {
    if (schema.tipUpsell) {
      setStep(Step.LeaveTip);
      // If there is a tip upsell, we schedule the charge in case the user abandons the tip form
      if (!preventSchedule) {
        await scheduleCharge({ formClientSecret });
      }
    } else {
      // If there's no tip upsell, complete the charge immediately
      await chargeNow({ formClientSecret });
      onComplete({ success: true });
    }
  };

  const handleUpdatePendingDonation = async (donor: DonorFormState) => {
    if (!formId) return;
    setIsLoading(true);

    try {
      const pendingContribution = await updatePendingContribution({
        amountInCents: selectedAmount || customAmount || 0,
        recurringContributionFrequency: recurring ? schema.recurringFrequency : RecurringContributionFrequency.ONCE,
        isCoveringProcessingFee: false,
        clientSecret: formClientSecret,
        // If we don't need card info, we can schedule or charge now
        // If we aren't doing an upsell we can charge new, else we schedule
        scheduleCharge: !needCardInfo && schema.tipUpsell,
        chargeNow: !needCardInfo && !schema.tipUpsell,
        ...donor,
      });

      setFormClientSecret(pendingContribution.client_secret);

      if (needCardInfo) {
        setStep(Step.CreditCard);
      } else {
        await handleCheckoutFormConfirm({ preventSchedule: !needCardInfo }); // If we've already scheduled the charge, we do not have to do it again
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCustomAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Check if the input has more than 2 decimal places
    if (!/^\d*\.?\d{0,2}$/.test(inputValue)) {
      e.target.value = inputValue.slice(0, -1);
      return; // Don't update if there are more than 2 decimal places
    }

    const value = parseFloat(inputValue);
    if (isNaN(value)) {
      setCustomAmount(1);
    } else {
      setCustomAmount(Math.round(value * 100));
    }
  };

  const handleCustomAmountInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();

    if (inputValue === "") {
      // Set to undefined if the input is empty
      setCustomAmount(undefined);
      e.target.value = "";
      return;
    }

    let value = parseFloat(inputValue);

    if (isNaN(value)) {
      value = 0;
    }

    // Value must be a minimum of $1.00 and a maximum of $5,000.00
    value = Math.min(Math.max(value, 1), 5_000);

    // Format as USD with 2 decimal places
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    // Remove the dollar sign as we're working with numbers
    const numericValue = formattedValue.replace(/[$,]/g, "");

    // Update the input value and state
    e.target.value = numericValue;
    setCustomAmount(Math.round(parseFloat(numericValue) * 100));
  };

  return (
    <>
      <VStack spacing={8} width="full" maxWidth="2xl" display={step === Step.SelectAmount ? "flex" : "none"}>
        <Text fontSize="xl" fontWeight="800">
          Select an amount to donate to {orgName}
        </Text>
        <Grid templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
          {schema.amountPresetsInCents.map((amount) => (
            <Button
              key={amount}
              onClick={() => setSelectedAmount(amount)}
              colorScheme={selectedAmount === amount ? "blue" : "gray"}
              boxShadow={selectedAmount === amount ? "0 0 0 3px #3182ce" : undefined}
              isDisabled={readyForCheckout}
            >
              {/* If the value is in whole dollar, don't show decimal places */}
              {amount % 100 === 0 ? `$${(amount / 100).toFixed(0)}` : `$${(amount / 100).toFixed(2)}`}
            </Button>
          ))}

          <InputGroup
            boxShadow={selectedAmount === undefined ? "0 0 0 3px #3182ce" : undefined}
            borderRadius="md"
            onClick={() => {
              const input = document.getElementById("custom-amount");
              if (input) {
                input.focus();
              }
            }}
          >
            <InputLeftAddon px={2} fontWeight="600" fontSize="md">
              $
            </InputLeftAddon>
            <Input
              id="custom-amount"
              type="number"
              step="any"
              pl={2}
              placeholder="other"
              _focus={{ boxShadow: "none", borderColor: "transparent" }}
              onFocus={() => setSelectedAmount(undefined)}
              onChange={handleCustomAmountInputChange}
              onBlur={handleCustomAmountInputBlur}
              isDisabled={readyForCheckout}
              defaultValue={customAmount ? (customAmount / 100).toFixed(2) : undefined}
            />
          </InputGroup>
        </Grid>
        {schema.recurringFrequency !== RecurringContributionFrequency.ONCE && (
          <Box
            p={4}
            borderRadius="lg"
            borderWidth="2px"
            borderColor={recurring ? "blue.500" : "gray.300"}
            boxShadow={recurring ? "0 0 0 4px rgba(49, 130, 206, 0.25)" : undefined}
            transition="all 0.2s"
          >
            <Checkbox
              defaultChecked={schema.recurringDefaultChecked}
              fontWeight="700"
              fontSize="xl"
              width="full"
              onChange={(e) => setRecurring(e.target.checked)}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              size="lg"
            >
              <Text mt={-1}>I want to contribute {schema.recurringFrequency}</Text>

              <Text fontSize="sm" fontWeight="500">
                By checking this box, you agree to donate the selected amount {schema.recurringFrequency}.
                {schema.recurringFrequency === RecurringContributionFrequency.WEEKLY &&
                  ` After the General Election
                (November 5, 2024) your contribution will be switched to monthly. You can cancel at any time.`}
              </Text>
            </Checkbox>
          </Box>
        )}

        <VStack width="full" align="stretch">
          <Button
            onClick={() => {
              handleCreatePendingContribution({}).then((clientSecret) => {
                if (!clientSecret) return;
                setStep(Step.DonorInfo);
                setNeedCardInfo(true);
              });
            }}
            isDisabled={!!!(selectedAmount || customAmount || 0)}
            width="full"
            colorScheme="blue"
            fontSize="lg"
            isLoading={isLoading}
          >
            Donate with card
          </Button>
          <PaymentMethodButtons
            stripeConnectedAccountId={stripeConnectedAccountId}
            getStripeClientSecret={getStripeClientSecret}
            amountInCents={selectedAmount || customAmount || 0}
            onConfirm={(donorPrefillInfo: DonorPrefillInfo) => {
              setDonorPrefillInfo(donorPrefillInfo);
              setStep(Step.DonorInfo);
            }}
          />
        </VStack>
      </VStack>

      <VStack spacing={8} width="full" maxWidth="2xl" display={step === Step.DonorInfo ? "flex" : "none"}>
        <Text fontSize="xl" fontWeight="800">
          Enter your information
        </Text>
        <DonorForm
          donorPrefillInfo={{ ...queryPrefillData, ...donorPrefillInfo }}
          onSubmit={handleUpdatePendingDonation}
          orgName={orgName}
          orgPrivacyPolicyUrl={orgPrivacyPolicyUrl}
        />
      </VStack>

      <VStack spacing={8} width="full" maxWidth="2xl" display={step === Step.CreditCard ? "flex" : "none"}>
        <Text fontSize="xl" fontWeight="800">
          Complete your donation
        </Text>
        <CheckoutForm
          getStripeClientSecret={getStripeClientSecret}
          formClientSecret={formClientSecret}
          stripeConnectedAccountId={stripeConnectedAccountId}
          amountInCents={selectedAmount || customAmount || 0}
          onConfirm={async () => await handleCheckoutFormConfirm({ preventSchedule: false })}
        />
      </VStack>

      <VStack spacing="0" width="full" maxWidth="2xl" display={step === Step.LeaveTip ? "flex" : "none"}>
        <Text fontSize="xl" fontWeight="800">
          Thank you!
        </Text>
        {/* Mount this late so that the default value can be assigned *after* donation amount is set. */}
        {step === Step.LeaveTip && (
          <TipForm
            formClientSecret={formClientSecret}
            donationAmountInCents={selectedAmount || customAmount || 0}
            defaultTipInCents={Math.max(50, (selectedAmount || customAmount || 0) * 0.1)}
            orgName={orgName}
          />
        )}
      </VStack>
    </>
  );
};

// Dynamic import with no SSR to avoid pre-loading the Stripe SDK
export default dynamic(() => Promise.resolve(ContributionForm), {
  ssr: false,
});
