export const generateUUID = async (): Promise<string> => {
  // Check if the browser supports crypto.randomUUID()
  if (crypto?.randomUUID) {
    // Generate a random UUID
    return crypto.randomUUID();
  } else {
    // If it doesn't, go fetch one from the server
    const response = await fetch("/api/uuid");
    const data = await response.json();
    return data.uuid;
  }
};
