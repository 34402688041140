/**
 * This <Markdown /> component CAN NOT be used within a <Text> component or a <p> tag.
 * It will break react rehydration.
 *

 **/
import React from "react";

import { Heading, Link } from "@chakra-ui/react";

import ReactMarkdown, { ExtraProps } from "react-markdown";
import remarkGfm from "remark-gfm";

const YouTubeEmbed = ({ id }: { id: string }) => (
  <iframe
    style={{ width: "100%", maxWidth: "100%", marginTop: "0.25rem", marginBottom: "0.25rem", aspectRatio: "16/9" }}
    src={`https://www.youtube.com/embed/${id}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded YouTube"
  />
);

const VimeoEmbed = ({ id }: { id: string }) => (
  <iframe
    style={{ width: "100%", maxWidth: "100%", marginTop: "0.25rem", marginBottom: "0.25rem", aspectRatio: "16/9" }}
    src={`https://player.vimeo.com/video/${id}`}
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
    title="Embedded Vimeo"
  />
);

const VideoEmbed = ({ src }: { src: string }) => (
  <video controls style={{ width: "100%", maxWidth: "100%", marginTop: "0.25rem", marginBottom: "0.25rem" }}>
    <source src={src} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
);

const ImageEmbed = ({ src, alt }: { src: string; alt: string }) => (
  <img src={src} alt={alt} style={{ maxWidth: "100%", marginTop: "0.25rem", marginBottom: "0.25rem" }} /> // eslint-disable-line
);

const youtubeRegex = /^https?:\/\/(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)$/;
const vimeoRegex = /^https?:\/\/(www\.)?vimeo\.com\/(\d+)$/;
const videoRegex = /^https?:\/\/(.+)\.mp4$/;
const imageRegex = /^https?:\/\/(.+)\.(jpg|jpeg|png|gif|svg)$/;

const Markdown: React.FC<any> = ({ children }) => {
  const renderers = {
    h1: ({ children }: JSX.IntrinsicElements["h1"] & ExtraProps) => (
      <Heading as="h1" fontSize="2xl" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    h2: ({ children }: JSX.IntrinsicElements["h2"] & ExtraProps) => (
      <Heading as="h2" fontSize="xl" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    h3: ({ children }: JSX.IntrinsicElements["h3"] & ExtraProps) => (
      <Heading as="h3" fontSize="lg" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    h4: ({ children }: JSX.IntrinsicElements["h4"] & ExtraProps) => (
      <Heading as="h4" fontSize="md" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    h5: ({ children }: JSX.IntrinsicElements["h5"] & ExtraProps) => (
      <Heading as="h5" fontSize="sm" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    h6: ({ children }: JSX.IntrinsicElements["h6"] & ExtraProps) => (
      <Heading as="h6" fontSize="xs" fontWeight="bold" mb={4}>
        {children}
      </Heading>
    ),
    a: ({ href, children }: JSX.IntrinsicElements["a"] & ExtraProps) => {
      if (href === undefined) return;

      if (youtubeRegex.test(href)) {
        const match = youtubeRegex.exec(href);
        if (match !== null) return <YouTubeEmbed id={match[2]} />;
      } else if (vimeoRegex.test(href)) {
        const match = vimeoRegex.exec(href);
        if (match !== null) return <VimeoEmbed id={match[2]} />;
      } else if (videoRegex.test(href)) {
        return <VideoEmbed src={href} />;
      } else if (imageRegex.test(href)) {
        return <ImageEmbed src={href} alt={children as string} />;
      }
      return (
        <Link color="blue.500" textDecoration="underline" href={href} isExternal>
          {children}
        </Link>
      );
    },
  };

  return (
    <ReactMarkdown
      components={renderers}
      // rehypePlugins={[rehypeRaw]} TODO: Sanitize and limit which tags are allowed
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
