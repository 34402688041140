import React from "react";

import { Heading, HeadingProps } from "@chakra-ui/react";

interface FormTitleProps extends HeadingProps {}

const FormTitle = ({ ...props }: FormTitleProps): JSX.Element => {
  return <Heading fontSize="4xl" fontWeight="800" as="h1" color="gray.900" mb={2} {...props} />;
};

export default FormTitle;
