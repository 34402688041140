import React from "react";

import { Box, Image } from "@chakra-ui/react";

interface FormBannerProps {
  src?: string;
}

const FormBanner = ({ src }: FormBannerProps): JSX.Element | null => {
  if (src) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="full" borderRadius="xl" overflow="hidden">
        <Image src={src} alt="Form Banner" maxHeight="200px" />
      </Box>
    );
  }

  return null;
};

export default FormBanner;
