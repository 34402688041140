import { Box, Center } from "@chakra-ui/react";

import { PreviewDimensionMap } from "@lib/types";

interface PreviewBoxProps {
  previewDimensionName?: string;
  maxWidth: number;
  children: React.ReactNode;
}

/** If a preview dimension is given, presents the given area in a restricted dimension. */
const PreviewBox = ({ previewDimensionName, maxWidth, children }: PreviewBoxProps) => {
  if (!previewDimensionName) return <>{children}</>;

  const { width, height } = PreviewDimensionMap[previewDimensionName];

  return (
    <Center height="100vh">
      <Box display="grid" width={width > maxWidth ? maxWidth : width} height={height} overflow="auto">
        {children}
      </Box>
    </Center>
  );
};

export default PreviewBox;
