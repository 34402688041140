import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useDebouncedValue = <T,>(value: T | (() => T), delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<any>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebouncedState = <T,>(
  initialState: T | (() => T),
  delay: number = 500
): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(initialState);
  const debouncedValue = useDebouncedValue<T>(value, delay);

  return [debouncedValue, setValue, value];
};

export default useDebouncedState;
