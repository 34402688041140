import React, { useEffect, useState } from "react";

import { Box, ButtonGroup, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";

import DesktopIcon from "@components/DesktopIcon";
import FormClosedPage from "@components/FormClosedPage";
import FormDescription from "@components/FormDescription";
import FormFooter from "@components/FormFooter";
import FormPaidForBy from "@components/FormPaidForBy";
import MobileIcon from "@components/MobileIcon";
import PreviewBox from "@components/PreviewBox";
import PreviewNotice from "@components/PreviewNotice";
import SwitchboardThankYouPage from "@components/SwitchboardThankYouPage";
import ContributionDisclosure from "@components/contributions/ContributionDisclosure";
import ContributionForm from "@components/contributions/ContributionForm";
import { DEFAULT_FORM_DOMAIN } from "@lib/constants";
import { useSession } from "@lib/session";
import { ensureURLProtocol } from "@lib/url";
import { GetServerSideProps, NextPage } from "next";
import { useRouter } from "next/router";

import FormBanner from "../components/FormBanner";
import FormHead from "../components/FormHead";
import FormTitle from "../components/FormTitle";
import RenderSchema from "../components/RenderSchema";
import { formView, getDraftForm, getPublishedForm, getPublishedFormByDomain } from "../lib/api";
import { formItemsToSchema, formItemsToUISchema } from "../lib/schema";
import { PreviewDimensionMap, WebFormApiObject } from "../lib/types";

const deepOmitUndefined = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  // An array of strings
  const { formTokens, refcodeSB = null, __verbose, __embedded } = context.query;
  let form;
  let mode = "published";

  // Check if this form is hosted on a custom domain aka not forms.oneswitchboard.com
  const domain = context.req.headers.host || "";
  // To test custom domains locally, comment the above and uncommon the below,
  // plus add `forms.swtch.to` as a custom forms domain to your local database.
  // const domain = "forms.swtch.to";
  const isCustomDomain = !(domain && domain === DEFAULT_FORM_DOMAIN);

  try {
    if (!formTokens || formTokens.length === 0) {
      return {
        notFound: true,
      };
    } else if (isCustomDomain && formTokens.length === 1) {
      form = await getPublishedFormByDomain(domain, formTokens[0]);
    } else if (formTokens.length > 1) {
      // Assume multiple tokens are an org slug, and a form slug
      if (formTokens[0] === "preview") {
        mode = "preview";
        form = await getDraftForm(formTokens[1]);
      } else {
        form = await getPublishedForm(formTokens[0], formTokens[1]);
      }
    } else {
      return {
        notFound: true,
      };
    }
  } catch (e) {
    if (!!!form) {
      return {
        notFound: true,
      };
    }
  }

  const schema = deepOmitUndefined(formItemsToSchema(form.schema.form.items));

  const uiSchema = deepOmitUndefined(formItemsToUISchema(form.schema));

  const isContributionsForm = form.webform_type === "CONTRIBUTION";

  return {
    props: {
      mode,
      form,
      schema,
      uiSchema,
      refcodeSB,
      icf: isContributionsForm,
      verbose: __verbose === "t",
      embedded: __embedded === "t",
    },
  };
};

interface RenderFormPageProps {
  mode: "preview" | "published";
  form: WebFormApiObject;
  schema?: any;
  uiSchema?: any;
  refcodeSB: string | null;
  icf: boolean; // isContributionsForm
  verbose?: boolean;
  embedded?: boolean;
}

const RenderFormPage: NextPage<RenderFormPageProps> = ({
  mode,
  form,
  schema,
  uiSchema,
  refcodeSB,
  icf, // isContributionsForm
  verbose,
  embedded,
}) => {
  const { sessionId } = useSession();

  const [isComplete, setIsComplete] = useState(false);
  const { query } = useRouter();
  // remove the formTokens key from the query object
  delete query.formTokens;
  const [formViewed, setFormViewed] = useState(false);

  // When undefined, this means "Desktop" (just use full screen resolution).
  const [previewDimensionName, setPreviewDimensionName] = useState<string | undefined>();

  // Set the background based on whether or not we're in preview mode. We set it
  // on the <body> tag since some browsers let you do a bounce scroll that
  // reveals the <body> background outside of the limits of Chakra-UI's
  // components.
  useEffect(() => {
    let backgroundColor = form.schema.page?.backgroundColor || "";
    if (previewDimensionName) backgroundColor = "black";
    document.body.style.backgroundColor = backgroundColor;
  }, [previewDimensionName, form.schema.page?.backgroundColor]);

  const handleFormComplete = (data: any) => {
    try {
      if (!!form.schema.form.postSubmitRedirect && !!form.schema.form.postSubmitRedirectUrl) {
        const redirectUrl = new URL(ensureURLProtocol(form.schema.form.postSubmitRedirectUrl));
        const refcode = refcodeSB || data?.refcodeSB;
        if (
          ["http:", "https:"].includes(redirectUrl.protocol) &&
          redirectUrl.host === "secure.actblue.com" &&
          !!refcode
        ) {
          redirectUrl.searchParams.append("refcodeSB", refcode);
        }
        window.location.href = redirectUrl.toString();
      }
    } catch (e) {
      setIsComplete(true);
    }
    setIsComplete(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (embedded && window.parent !== window.self) {
        // If we're embedded, send a message to the parent window with the height of the form
        window.parent.postMessage(
          { height: document.body.scrollHeight, type: "resize", form: form.slug, source: "switchboard-forms" },
          "*"
        );
      }
    };

    // Handle the initial size
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [embedded, form.slug]);

  useEffect(() => {
    const handleFormView = () => {
      if (mode === "published" && !!sessionId && !formViewed) {
        setFormViewed(true);
        formView(form.organization, form.slug);
      }
    };

    handleFormView();
  }, [mode, form.organization, form.slug, formViewed, refcodeSB, sessionId]);

  return (
    <>
      {/* If preview mode and not an ICF form, which doesn't currently support the way we do previews */}
      {mode === "preview" && !icf && (
        <ButtonGroup
          isAttached
          variant="ghost"
          position="fixed"
          bottom="12px"
          left="12px"
          borderRadius="md"
          borderWidth="1.25px"
          borderColor="gray.200"
          background="white"
          boxShadow="0px 1.25px 2.5px 0px rgba(0, 0, 0, 0.06), 0px 1.25px 3.75px 0px rgba(0, 0, 0, 0.10);"
          zIndex="overlay"
        >
          <IconButton
            variant="ghost"
            aria-label="Preview as a desktop user"
            icon={<DesktopIcon />}
            color={previewDimensionName ? undefined : "#1A72D4"}
            onClick={() => setPreviewDimensionName(undefined)}
          />
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              aria-label="Preview as a mobile user"
              icon={<MobileIcon />}
              color={previewDimensionName ? "#1A72D4" : undefined}
            />
            <MenuList>
              {Object.keys(PreviewDimensionMap).map((name) => (
                <MenuItem key={name} onClick={() => setPreviewDimensionName(name)}>
                  {name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      )}
      <PreviewBox previewDimensionName={previewDimensionName} maxWidth={700}>
        {mode === "preview" && <PreviewNotice />}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx="auto"
          width="full"
          height="full"
          maxWidth="6xl"
          p={embedded ? 0 : 5}
          bg={form.schema.page?.backgroundColor}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="full"
            height="full"
            maxWidth={embedded ? "full" : icf ? "full" : 700}
            p={{ base: 4, sm: "32px" }}
            flex={1}
            bg="white"
            borderRadius={embedded ? 0 : "xl"}
          >
            <Box
              width="full"
              // Only use row layout on desktop if it's a contributions form
              flexDirection={{ base: "column", md: icf ? "row" : "column" }}
              display="flex"
              flex={1}
            >
              <Box width="full" p={icf ? 5 : 0}>
                <FormBanner src={form.schema.page?.banner} />
                <FormHead form={form} />
                <FormTitle>{form.schema.page?.title || form.title}</FormTitle>
                <FormDescription>{form.schema.page?.description}</FormDescription>
              </Box>
              <Box width="full" p={icf ? 5 : 0}>
                {form.is_closed ? (
                  <FormClosedPage header={form.schema.form.closedHeader} body={form.schema.form.closedBody} />
                ) : isComplete ? (
                  <SwitchboardThankYouPage
                    header={form.schema.form.postSubmitHeader}
                    body={form.schema.form.postSubmitBody}
                  />
                ) : (
                  <>
                    {icf ? ( // isContributionsForm
                      <ContributionForm
                        formId={form.public_id}
                        onComplete={handleFormComplete}
                        schema={form.schema.contribution!}
                        orgName={form.organization_name}
                        // orgPrivacyPolicyUrl="http://www.dscc.org/privacy-policy"
                      />
                    ) : (
                      <RenderSchema
                        mode={mode}
                        orgSlug={form.organization}
                        formSlug={form.slug}
                        schema={schema}
                        uiSchema={uiSchema}
                        onComplete={handleFormComplete}
                        verbose={verbose}
                        refcodeSB={refcodeSB}
                        initialData={query}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>

            {icf && <ContributionDisclosure schema={form.schema} />}
            <FormFooter>{form.schema.page?.footer}</FormFooter>
            {form.schema.page?.paidForBy && <FormPaidForBy>{form.schema.page?.paidForBy}</FormPaidForBy>}
          </Box>
          {verbose && (
            <Box width="full" maxWidth="6xl">
              <Box as="pre" bg="green.50" p={3} m={3}>
                {JSON.stringify(form, null, 2)}
              </Box>
              <Box as="pre" bg="blue.50" p={3} m={3}>
                {JSON.stringify(schema, null, 2)}
              </Box>
              <Box as="pre" bg="red.50" p={3} m={3}>
                {JSON.stringify(uiSchema, null, 2)}
              </Box>
            </Box>
          )}
        </Box>
      </PreviewBox>
    </>
  );
};

export default RenderFormPage;
