import React from "react";

import { Box } from "@chakra-ui/react";

interface PreviewNoticeProps {}

const PreviewNotice = ({}: PreviewNoticeProps): JSX.Element => {
  return (
    <Box borderStyle="dashed" borderWidth="2px" borderColor="orange.400" bg="orange.100" p={3} textAlign="center">
      <Box fontSize="xl" fontWeight="800">
        Form Preview
      </Box>
      <Box fontSize="sm" fontWeight="400">
        This URL is for preview purposes only, and can not be submitted.
      </Box>
    </Box>
  );
};

export default PreviewNotice;
