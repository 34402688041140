import { useEffect, useState } from "react";

import { generateUUID } from "./uuid";

type SessionHook = () => {
  sessionId: string;
  updateSessionId: (sessionId: string) => void;
};

// useSession hook to manage and retrieve the user's unique session identifier
export const useSession: SessionHook = () => {
  const [sessionId, setSessionId] = useState<string>("");

  const updateSessionId = (sessionId: string) => {
    localStorage.setItem("sessionId", sessionId);
    setSessionId(sessionId);
  };

  useEffect(() => {
    // Check if the session ID already exists in local storage
    const storedSessionId = localStorage.getItem("sessionId");

    if (storedSessionId) {
      // Use the existing session ID if available
      setSessionId(storedSessionId);
    } else {
      // Generate a new session ID, remove the dashes, store it, and update the state
      generateUUID().then((newSessionId) => updateSessionId(newSessionId.replace(/-/g, "")));
    }
  }, []);

  return {
    sessionId,
    updateSessionId,
  };
};
