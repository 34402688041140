import { Box } from "@chakra-ui/react";

import Markdown from "@components/Markdown";
import { WebFormSchema } from "@lib/types";
import dynamic from "next/dynamic";

interface ContributionDisclosureProps {
  schema?: WebFormSchema;
}

const ContributionDisclosure = ({ schema }: ContributionDisclosureProps) => {
  return (
    <Box fontSize="xs" marginTop={10}>
      <Markdown>{schema?.contribution?.disclosure}</Markdown>
    </Box>
  );
};

// Dynamic import with no SSR to avoid loading any contributions related code into non-contributions forms
export default dynamic(() => Promise.resolve(ContributionDisclosure), {
  ssr: false,
});
