export const ensureURLProtocol = (urlString: string, fallbackProtocol: string = "http"): string => {
  // Regular expression to check for the presence of a protocol scheme (e.g., "http://", "https://", "tel://", "mailto://", etc.).
  const protocolRegex = /^[a-z][a-z0-9+.-]*:\/\//i;

  // Check if the string does not start with a protocol scheme, prepend the fallback protocol if not
  if (!protocolRegex.test(urlString.trim().toLowerCase())) {
    urlString = `${fallbackProtocol}://${urlString}`;
  }

  return urlString.trim();
};
