import { createIcon } from "@chakra-ui/react";

const MobileIcon = createIcon({
  displayName: "MobileIcon",
  viewBox: "0 0 11 16",
  path: (
    <path
      d="M8.53125 0.5H1.96875C0.933106 0.5 0.09375 1.33936 0.09375 2.375V13.625C0.09375 14.6606 0.933106 15.5 1.96875 15.5H8.53125C9.56689 15.5 10.4062 14.6606 10.4062 13.625V2.375C10.4062 1.33936 9.56543 0.5 8.53125 0.5ZM9 13.625C9 13.8835 8.78971 14.0938 8.53125 14.0938H1.96875C1.71035 14.0938 1.5 13.8828 1.5 13.625V2.375C1.5 2.11654 1.71029 1.90625 1.96875 1.90625H8.53125C8.78906 1.90625 9 2.1166 9 2.375V13.625ZM6.1875 12.2188H4.3125C4.05363 12.2188 3.84375 12.4286 3.84375 12.6875C3.84375 12.9464 4.05363 13.1562 4.3125 13.1562H6.1875C6.44643 13.1562 6.65625 12.9464 6.65625 12.6875C6.65625 12.4286 6.44531 12.2188 6.1875 12.2188Z"
      fill="currentColor"
    />
  ),
});

export default MobileIcon;
