import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

const FormPaidForBy = ({ children, ...props }: BoxProps) => {
  return (
    <Box
      className="form-paid-for-by"
      fontSize="xs"
      fontWeight="500"
      px={2}
      py={1}
      mx="auto"
      my={3}
      color="gray.500"
      borderWidth="1px"
      borderColor="gray.500"
      {...props}
    >
      {children}
    </Box>
  );
};

export default FormPaidForBy;
