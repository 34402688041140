import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";

import Markdown from "./Markdown";

const FormFooter = ({ children, ...props }: BoxProps): JSX.Element => {
  return (
    <Box fontSize="sm" fontWeight="400" pt={5} pb={1} color="gray.600" {...props}>
      <Markdown>{children}</Markdown>
    </Box>
  );
};

export default FormFooter;
